import "./Events.css";
import React, { useEffect, useState } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import db from "../firebase";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/prism";

const getDirectionsButton = (url) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="tooltip" className="tooltip">
          Get directions
        </Tooltip>
      }
    >
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded text-sm px-3 py-2.5 mx-4 text-center w-fit"
      >
        <svg
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#ffffff"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M5.7 15C4.03377 15.6353 3 16.5205 3 17.4997C3 19.4329 7.02944 21 12 21C16.9706 21 21 19.4329 21 17.4997C21 16.5205 19.9662 15.6353 18.3 15M12 9H12.01M18 9C18 13.0637 13.5 15 12 18C10.5 15 6 13.0637 6 9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.68629 18 9ZM13 9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9Z"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
          </g>
        </svg>
      </a>
    </OverlayTrigger>
  );
};

const getAddToCalendarButton = (url) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="tooltip" className="tooltip">
          Add to calendar
        </Tooltip>
      }
    >
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="hidden sm:block text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded text-sm px-3 py-2.5 text-center w-fit"
      >
        <svg
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M17 11C14.2386 11 12 13.2386 12 16C12 18.7614 14.2386 21 17 21C19.7614 21 22 18.7614 22 16C22 13.2386 19.7614 11 17 11ZM17 11V9M2 9V15.8C2 16.9201 2 17.4802 2.21799 17.908C2.40973 18.2843 2.71569 18.5903 3.09202 18.782C3.51984 19 4.0799 19 5.2 19H13M2 9V8.2C2 7.0799 2 6.51984 2.21799 6.09202C2.40973 5.71569 2.71569 5.40973 3.09202 5.21799C3.51984 5 4.0799 5 5.2 5H13.8C14.9201 5 15.4802 5 15.908 5.21799C16.2843 5.40973 16.5903 5.71569 16.782 6.09202C17 6.51984 17 7.0799 17 8.2V9M2 9H17M5 3V5M14 3V5M15 16H17M17 16H19M17 16V14M17 16V18"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
          </g>
        </svg>
      </a>
    </OverlayTrigger>
  );
};

const getClothingGuideButton = (url) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="tooltip" className="tooltip">
          See clothing guide for event
        </Tooltip>
      }
    >
      <HashLink
        to={url}
        className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded text-sm px-3 py-2.5 mx-4 text-center w-fit"
      >
        <svg
          viewBox="0 0 24 24"
          width="25px"
          height="25px"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M9.53589 6.90909C9.53589 5.85473 10.4868 5 11.6599 5C12.8329 5 13.7839 5.85473 13.7839 6.90909C13.7839 7.40532 13.6046 7.85733 13.2925 8.19682C12.6948 8.84706 11.8015 9.50197 11.8015 10.3455V10.6299M11.8015 10.6299C12.533 10.6214 13.2674 10.8246 13.8845 11.2406L21.317 16.2509C22.6234 17.1315 21.9305 19 20.2975 19H3.70254C2.08721 19 1.38322 17.1648 2.65531 16.27L9.751 11.2787C10.3534 10.855 11.076 10.6383 11.8015 10.6299Z"
              stroke="#ffffff"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>{" "}
          </g>
        </svg>
      </HashLink>
    </OverlayTrigger>
  );
};

function HinduCeremonyRehearsal() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-2xl mb-8 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <span className="text-left flex items-center font-light text-gray-500 pb-2 sm:text-lg">
          <svg
            fill="gray"
            height="20px"
            width="20px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 224.549 224.549"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path d="M223.476,108.41c-1.779-2.96-44.35-72.503-111.202-72.503S2.851,105.45,1.072,108.41c-1.43,2.378-1.43,5.351,0,7.729 c1.779,2.96,44.35,72.503,111.202,72.503s109.423-69.543,111.202-72.503C224.906,113.761,224.906,110.788,223.476,108.41z M112.274,173.642c-49.925,0-86.176-47.359-95.808-61.374c9.614-14.032,45.761-61.36,95.808-61.36 c49.925,0,86.176,47.359,95.808,61.374C198.468,126.313,162.321,173.642,112.274,173.642z"></path>{" "}
                <path d="M112.274,61.731c-27.869,0-50.542,22.674-50.542,50.543c0,27.868,22.673,50.54,50.542,50.54 c27.868,0,50.541-22.672,50.541-50.54C162.815,84.405,140.143,61.731,112.274,61.731z M112.274,147.814 c-19.598,0-35.542-15.943-35.542-35.54c0-19.599,15.944-35.543,35.542-35.543s35.541,15.944,35.541,35.543 C147.815,131.871,131.872,147.814,112.274,147.814z"></path>{" "}
                <path d="M112.274,92.91c-10.702,0-19.372,8.669-19.372,19.364c0,10.694,8.67,19.363,19.372,19.363 c10.703,0,19.373-8.669,19.373-19.363C131.647,101.579,122.977,92.91,112.274,92.91z"></path>{" "}
              </g>{" "}
            </g>
          </svg>
          <p className="ml-2">Visible to Bridal Party Only</p>
        </span>
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-5xl font-extrabold">Hindu Ceremony Rehearsal</span>
        </div>
        <p className="font-light text-gray-500 pt-4 sm:text-lg">4:00 pm - 5:00 pm</p>
        <p className="font-light text-gray-500 pt-4 sm:text-lg">Lakeview Pavilion</p>
        <p className="font-light text-gray-500 pt-4 sm:text-lg">
          45 Lakeview Rd, Foxborough, MA 02035
        </p>
        <p className="font-light text-gray-500 py-4 sm:text-lg">
          This is completely optional! If you are in the area, please join us!
        </p>
        <div className="inline-flex self-center">
          {getDirectionsButton("https://maps.app.goo.gl/3E3KJcUp4MZmzX369")}
          {getAddToCalendarButton(
            "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NHJzamYwOW9zdTlyNzhsNDQzcXNyZmJoOTggODlhMGIxMGFkODRkMWYyZjhhYWNhMzIzMTkxNGIxMWFlNzg2ODNhMmY0ZDIzNmZjNGNiOTY2ZTJiMGViYTdlY0Bn&tmsrc=89a0b10ad84d1f2f8aaca3231914b11ae78683a2f4d236fc4cb966e2b0eba7ec%40group.calendar.google.com"
          )}
        </div>
      </div>
    </>
  );
}

function ChurchRehearsal() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-2xl mb-8 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <span className="text-left flex items-center font-light text-gray-500 pb-2 sm:text-lg">
          <svg
            fill="gray"
            height="20px"
            width="20px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 224.549 224.549"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path d="M223.476,108.41c-1.779-2.96-44.35-72.503-111.202-72.503S2.851,105.45,1.072,108.41c-1.43,2.378-1.43,5.351,0,7.729 c1.779,2.96,44.35,72.503,111.202,72.503s109.423-69.543,111.202-72.503C224.906,113.761,224.906,110.788,223.476,108.41z M112.274,173.642c-49.925,0-86.176-47.359-95.808-61.374c9.614-14.032,45.761-61.36,95.808-61.36 c49.925,0,86.176,47.359,95.808,61.374C198.468,126.313,162.321,173.642,112.274,173.642z"></path>{" "}
                <path d="M112.274,61.731c-27.869,0-50.542,22.674-50.542,50.543c0,27.868,22.673,50.54,50.542,50.54 c27.868,0,50.541-22.672,50.541-50.54C162.815,84.405,140.143,61.731,112.274,61.731z M112.274,147.814 c-19.598,0-35.542-15.943-35.542-35.54c0-19.599,15.944-35.543,35.542-35.543s35.541,15.944,35.541,35.543 C147.815,131.871,131.872,147.814,112.274,147.814z"></path>{" "}
                <path d="M112.274,92.91c-10.702,0-19.372,8.669-19.372,19.364c0,10.694,8.67,19.363,19.372,19.363 c10.703,0,19.373-8.669,19.373-19.363C131.647,101.579,122.977,92.91,112.274,92.91z"></path>{" "}
              </g>{" "}
            </g>
          </svg>
          <p className="ml-2">Visible to Bridal Party Only</p>
        </span>
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-5xl font-extrabold">Church Rehearsal</span>
        </div>
        <p className="font-light text-gray-500 pt-4 sm:text-lg">5:30 pm - 6:00 pm</p>
        <p className="font-light text-gray-500 pt-4 sm:text-lg">
          Transfiguration of the Lord Parish at Saint Mary Church
        </p>
        <p className="font-light text-gray-500 pt-4 sm:text-lg">
          14 Park St, North Attleborough, MA 02760
        </p>
        <p className="font-light text-gray-500 py-4 sm:text-lg">
          This is completely optional! If you are in the area, please join us!
        </p>
        <div className="inline-flex self-center">
          {getDirectionsButton("https://maps.app.goo.gl/HY3HSrER121HoTnb7")}
          {getAddToCalendarButton(
            "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MG5rNDhtYzExNm1jdmNjcHBvcHRqNXVpdTUgODlhMGIxMGFkODRkMWYyZjhhYWNhMzIzMTkxNGIxMWFlNzg2ODNhMmY0ZDIzNmZjNGNiOTY2ZTJiMGViYTdlY0Bn&tmsrc=89a0b10ad84d1f2f8aaca3231914b11ae78683a2f4d236fc4cb966e2b0eba7ec%40group.calendar.google.com"
          )}
        </div>
      </div>
    </>
  );
}

function HinduCeremony() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-2xl mb-8 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <div className="flex justify-center items-baseline my-4 min-h-[50px] my-auto">
          <span className="text-5xl font-extrabold">Hindu Ceremony</span>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 items-center">
          <div className="col-span-1">
            <img className="event-image m-auto" src={"/events/hindu_ceremony.png"} alt="" />
          </div>
          <div className="col-span-1">
            <p className="font-light text-gray-500 pt-4 sm:text-lg">10:45 am - 1:00 pm Ceremony</p>
            {/* Details expander: Arrival of groom, hindu ceremony, hor deurves, lunch, vidhai */}
            <p className="font-light text-gray-500 pt-4 sm:text-lg">
              1:00 pm - 3:30 pm Lunch and Madhuram Veppu
            </p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">Lakeview Pavilion</p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">
              45 Lakeview Rd, Foxborough, MA 02035
            </p>
          </div>
        </div>

        <div className="inline-flex self-center mt-8">
          {getDirectionsButton("https://maps.app.goo.gl/3E3KJcUp4MZmzX369")}
          {getAddToCalendarButton(
            "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NjQ5MGR2cjMxZTZpcHV1ZW1xZnFwdmlnY3EgODlhMGIxMGFkODRkMWYyZjhhYWNhMzIzMTkxNGIxMWFlNzg2ODNhMmY0ZDIzNmZjNGNiOTY2ZTJiMGViYTdlY0Bn&tmsrc=89a0b10ad84d1f2f8aaca3231914b11ae78683a2f4d236fc4cb966e2b0eba7ec%40group.calendar.google.com"
          )}
          {getClothingGuideButton("/clothing-guide/hindu-ceremony")}
        </div>
      </div>
    </>
  );
}

// Separate by days instead of events

// {/* Details expander: brunch 10:30 am - 12 pm at sonesta select (double check) */}

// Add an add to calendar button!!!

// Add a basic, detail and code toggle

function Church() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-2xl mb-8 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <div className="flex justify-center items-baseline my-4 min-h-[50px] my-auto">
          <span className="text-5xl font-extrabold">Catholic Ceremony</span>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 items-center">
          <div className="col-span-1">
            <img className="event-image m-auto" src={"/events/church_ceremony.png"} alt="" />
          </div>
          <div className="col-span-1">
            <p className="animate-fade-down italic text-xs font-light text-gray-500 pt-4 sm:text-md">
              Brunch served in Sonesta Meeting Room A from<br></br>10 am - 12 pm
            </p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">1:00 pm - 2:00 pm</p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">
              Transfiguration of the Lord Parish at Saint Mary Church
            </p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">
              14 Park St, North Attleborough, MA 02760
            </p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">
              The sacrament of Holy Matrimony will be solemnized by Retired Brigadier General and US
              Air Force Chaplain Rev. Fr. Richard Erikson
            </p>
          </div>
        </div>

        <div className="inline-flex self-center mt-8">
          {getDirectionsButton("https://maps.app.goo.gl/HY3HSrER121HoTnb7")}
          {getAddToCalendarButton(
            "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=N2w3bHZmM3Exdm05cGdraGN0bjQzcmlnaGsgODlhMGIxMGFkODRkMWYyZjhhYWNhMzIzMTkxNGIxMWFlNzg2ODNhMmY0ZDIzNmZjNGNiOTY2ZTJiMGViYTdlY0Bn&tmsrc=89a0b10ad84d1f2f8aaca3231914b11ae78683a2f4d236fc4cb966e2b0eba7ec%40group.calendar.google.com"
          )}
          {getClothingGuideButton("/clothing-guide/church-ceremony-reception")}
        </div>
      </div>
    </>
  );
}

function Reception() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-2xl mb-8 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <div className="flex justify-center items-baseline my-4 min-h-[50px] my-auto">
          <span className="text-5xl font-extrabold">Reception</span>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 items-center">
          <div className="col-span-1">
            <img className="event-image m-auto" src={"/events/reception.png"} alt="" />
          </div>
          <div className="col-span-1">
            <p className="animate-fade-down italic text-sm font-light text-gray-500 pt-4 sm:text-md">
              Shuttles leave from Sonesta at 5:40 & 6:10 pm
            </p>
            <p className="animate-fade-down font-light text-gray-500 pt-4 sm:text-lg">
              6:00 pm - 7:00 pm Cocktail Hour
            </p>
            <p className="animate-fade-down font-light text-gray-500 pt-4 sm:text-lg">
              7:00 pm - 11:00 pm Reception
            </p>
            <p className="animate-fade-down italic text-sm font-light text-gray-500 pt-4 sm:text-md">
              Shuttles leave from Lakeview at 10:45, 11:10 & 11:30 pm
            </p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">Lakeview Pavilion</p>
            <p className="font-light text-gray-500 pt-4 sm:text-lg">
              45 Lakeview Rd, Foxborough, MA 02035
            </p>
          </div>
        </div>

        {/* Details expander: shuttle times coming soon, cocktail hour, reception */}
        <div className="inline-flex self-center mt-8">
          {getDirectionsButton("https://maps.app.goo.gl/3E3KJcUp4MZmzX369")}
          {getAddToCalendarButton(
            "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=N2Z2NDB2MGgxaDY5c21xOGNoYmlpbnNqZGQgODlhMGIxMGFkODRkMWYyZjhhYWNhMzIzMTkxNGIxMWFlNzg2ODNhMmY0ZDIzNmZjNGNiOTY2ZTJiMGViYTdlY0Bn&tmsrc=89a0b10ad84d1f2f8aaca3231914b11ae78683a2f4d236fc4cb966e2b0eba7ec%40group.calendar.google.com"
          )}
          {getClothingGuideButton("/clothing-guide/church-ceremony-reception")}
        </div>
      </div>
    </>
  );
}

export function Events(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Run on component mount

  const [isBridalPartyLoggedIn, setIsBridalPartyLoggedIn] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Active", value: "1" },
    { name: "Radio", value: "2" },
    { name: "Radio", value: "3" },
  ];

  useEffect(() => {
    const prevBridalPartyPassword = atob(localStorage.getItem("kideejbridalparty"));

    onSnapshot(collection(db, "bridal_party_password"), (snapshot) => {
      const bridalPartyPassword = snapshot.docs[0].data().value;
      if (prevBridalPartyPassword === bridalPartyPassword) {
        setIsBridalPartyLoggedIn(true);
      }
    });
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <ButtonGroup className="inline-flex float-right mt-4 mr-4">
        {isBridalPartyLoggedIn &&
          radios.map((radio, idx) => (
            <button
              onClick={(e) => setRadioValue(radio.value)}
              rel="noreferrer"
              className={classNames(
                radio.value === radioValue ? "bg-primary-700" : "",
                radio.value === "1" ? "rounded-l-lg" : "",
                radio.value === "3" ? "rounded-r-lg" : "",
                "text-white bg-primary-600 font-medium text-sm px-3 py-2.5 text-center w-fit"
              )}
            >
              {radio.value === "2" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip" className="tooltip">
                      Code view
                    </Tooltip>
                  }
                >
                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M7 8L3 11.6923L7 16M17 8L21 11.6923L17 16M14 4L10 20"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </OverlayTrigger>
              ) : (
                <>
                  {radio.value === "1" ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip" className="tooltip">
                          Default view
                        </Tooltip>
                      }
                    >
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V8C1 9.65685 2.34315 11 4 11H20C21.6569 11 23 9.65685 23 8V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V8C3 8.55228 3.44772 9 4 9H20C20.5523 9 21 8.55228 21 8V4Z"
                            fill="#ffffff"
                          ></path>{" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23 16C23 14.3431 21.6569 13 20 13H4C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V16ZM21 16C21 15.4477 20.5523 15 20 15H4C3.44772 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V16Z"
                            fill="#ffffff"
                          ></path>{" "}
                        </g>
                      </svg>
                    </OverlayTrigger>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip" className="tooltip">
                            Timeline view
                          </Tooltip>
                        }
                      >
                        <svg
                          fill="#ffffff"
                          width="20px"
                          height="20px"
                          viewBox="0 0 32 32"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>timeline</title>{" "}
                            <path d="M0 26.016q0 2.496 1.76 4.224t4.256 1.76h20q2.464 0 4.224-1.76t1.76-4.224v-20q0-2.496-1.76-4.256t-4.224-1.76h-20q-2.496 0-4.256 1.76t-1.76 4.256v20zM4 26.016v-20q0-0.832 0.576-1.408t1.44-0.608h20q0.8 0 1.408 0.608t0.576 1.408v20q0 0.832-0.576 1.408t-1.408 0.576h-20q-0.832 0-1.44-0.576t-0.576-1.408zM6.016 11.008q0 1.248 0.864 2.144t2.112 0.864q0.96 0 1.728-0.576t1.088-1.44h4.384q0.352 0.896 1.088 1.472t1.728 0.544q1.248 0 2.112-0.864t0.896-2.144-0.896-2.112-2.112-0.896q-0.96 0-1.728 0.576t-1.088 1.44h-4.384q-0.32-0.864-1.088-1.44t-1.728-0.576q-1.248 0-2.112 0.896t-0.864 2.112zM8 11.008q0-0.416 0.288-0.704t0.704-0.288 0.704 0.288 0.32 0.704-0.32 0.704-0.704 0.288-0.704-0.288-0.288-0.704zM10.016 21.024q0 1.248 0.864 2.112t2.112 0.864q0.96 0 1.728-0.544t1.088-1.44h4.384q0.352 0.896 1.088 1.44t1.728 0.544q1.248 0 2.112-0.864t0.896-2.112-0.896-2.144-2.112-0.864q-0.96 0-1.728 0.544t-1.088 1.44h-4.384q-0.32-0.864-1.088-1.44t-1.728-0.544q-1.248 0-2.112 0.864t-0.864 2.144zM22.016 21.024q0-0.416 0.288-0.704t0.704-0.32 0.704 0.32 0.288 0.704-0.288 0.704-0.704 0.288-0.704-0.288-0.288-0.704z"></path>{" "}
                          </g>
                        </svg>
                      </OverlayTrigger>
                    </>
                  )}
                </>
              )}
            </button>
          ))}
      </ButtonGroup>
      {radioValue === "1" && (
        <section className="bg-white">
          <div className="py-4 max-w-screen-3xl lg:py-4">
            {isBridalPartyLoggedIn ? (
              <>
                <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
                  <h2 className="mt-2 mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                    Thursday November 7, 2024
                  </h2>
                </div>
                <div className="">{HinduCeremonyRehearsal()}</div>
                <div className="">{ChurchRehearsal()}</div>
              </>
            ) : (
              <></>
            )}
            <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                Friday November 8, 2024
              </h2>
            </div>
            <div className="">{HinduCeremony()}</div>
            <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                Saturday November 9, 2024
              </h2>
            </div>
            <div className="">
              {Church()}
              {Reception()}
            </div>
          </div>
        </section>
      )}
      {radioValue === "2" && (
        <section className="bg-white">
          <div className="py-4 max-w-screen-3xl lg:py-4">
            {isBridalPartyLoggedIn ? (
              <>
                <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
                  <h2 className="mt-2 mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                    Thursday November 7, 2024
                  </h2>
                </div>
                <div className="animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto rem-63">
                  <SyntaxHighlighter
                    wrapLongLines={true}
                    wrapLines={true}
                    language="javascript"
                    style={nightOwl}
                  >
                    {`const bridalPartyPerson = YOUR_NAME;
let hinduCeremonyRehearsalAttendees = { people: ['Debanjan', 'Krystal', 'Parents', 'Siblings'], location: ''};
let currentTime = Date.now();
let currentLocation = '';

if (bridalPartyPerson.location <= NOT_TOO_FAR) {
  hinduCeremonyRehearsalAttendees.people.push(bridalPartyPerson);
}

if (currentTime === new Date.setFullYear(2024).setMonth(10).setDate(7).setHours(14, 00, 0, 0)) {
  hinduCeremonyRehearsalAttendees.location = 'Lakeview Pavilioh';
}`}{" "}
                  </SyntaxHighlighter>
                </div>
                <div className="animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto rem-63">
                  <SyntaxHighlighter
                    wrapLongLines={true}
                    wrapLines={true}
                    language="javascript"
                    style={nightOwl}
                  >
                    {`const bridalPartyPerson = YOUR_NAME;
let churchRehearsalAttendees = { people: ['Debanjan', 'Krystal', 'Parents', 'Siblings'], location: ''};
let currentTime = Date.now();
let currentLocation = '';

if (bridalPartyPerson.location <= NOT_TOO_FAR) {
  churchRehearsalAttendees.people.push(bridalPartyPerson);
}

if (currentTime === new Date.setFullYear(2024).setMonth(10).setDate(7).setHours(17, 30, 0, 0)) {
  churchRehearsalAttendees.location = 'Transfiguration of the Lord Parish at Saint Mary Church';
}`}{" "}
                  </SyntaxHighlighter>
                  {/* `const eventData = {
  name: "Church Rehearsal",
  visibility: "Bridal Party"
  schedule: [
    {
      startTime: "5:30 PM",
      endTime: "6:00 PM",
      activity: "Rehearsal",
    },
  ],
  location: "Transfiguration of the Lord Parish at Saint Mary Church",
  note: "This is completely optional! If you are in the area, please join us"
};` */}
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                Friday November 8, 2024
              </h2>
            </div>
            <div className="animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto rem-63">
              <SyntaxHighlighter
                wrapLongLines={true}
                wrapLines={true}
                language="javascript"
                style={nightOwl}
              >
                {`enum Team {
  DEBANJAN,
  KRYSTAL
}
enum OutfitColor {
  BEIGE,
  LIGHT_BROWN,
  NUDE,
  PEACH,
  TAN,
  WHITE,
}
interface BridalPartyPerson {
  name: string;
  team: Team;
  outfitColor: OutfitColor;
  location: string;
  isGettingReady: boolean;
  isEating: boolean;
}

const bridalPartyPerson: BridalPartyPerson = new BridalPartyPerson({isGettingReady: true});

if (currentTime === new Date.setFullYear(2024).setMonth(10).setDate(8).setHours(10, 45, 0, 0)) {
  bridalPartyPerson.isGettingReady = false;
  bridalPartyPerson.location = 'Lakeview Pavilion';
} else if (currentTime === new Date.setFullYear(2024).setMonth(10).setDate(8).setHours(13, 0, 0, 0)) {
  bridalPartyPerson.isEating = true;
} else {
  bridalParty.location = "Stealing Debanjan's shoes";
}`}
                {/* {`const eventData = {
  name: "Hindu Ceremony",
  schedule: [
    {
      startTime: "10:45 AM",
      endTime: "1:00 PM",
      activity: "Ceremony",
    },
    {
      startTime: "1:00 PM",
      endTime: "3:00 PM",
      activity: "Lunch and Madhuram Veppu",
    },
  ],
  location: "Lakeview Pavilion",
};`} */}
              </SyntaxHighlighter>
            </div>
            <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                Saturday November 9, 2024
              </h2>
            </div>
            <div className="animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto rem-63">
              <SyntaxHighlighter
                wrapLongLines={true}
                wrapLines={true}
                language="javascript"
                style={nightOwl}
              >
                {`enum Team {
  DEBANJAN,
  KRYSTAL
}
enum Outfit {
  ROSE_GOLD_LEHENGA,
  GRAPHITE_SUIT
}
interface BridalPartyPerson {
  name: string;
  team: Team;
  outfit: Outfit;
  location: string;
  isGettingReady: boolean;
}

const bridalPartyPerson: BridalPartyPerson = new BridalPartyPerson({isGettingReady: true});

if (currentTime === new Date.setFullYear(2024).setMonth(10).setDate(8).setHours(13, 0, 0, 0)) {
  bridalPartyPerson.isGettingReady = false;
  bridalPartyPerson.location = 'Transfiguration of the Lord Parish at Saint Mary Church';
} else {
  bridalPartyPerson.location = 'Party bus';
}`}
                {/* {`const eventData = {
  name: "Church Ceremony",
  schedule: [
    {
      startTime: "1:00 PM",
      endTime: "2:00 PM",
      activity: "Ceremony",
    },
  ],
  location: "Transfiguration of the Lord Parish at Saint Mary Church",
};`} */}
              </SyntaxHighlighter>
            </div>
            <div className="animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto rem-63">
              <SyntaxHighlighter
                wrapLongLines={true}
                wrapLines={true}
                language="javascript"
                style={nightOwl}
              >
                {`enum Team {
  DIKKAL
}
enum Outfit {
  ANYTHING,
  ROSE_GOLD_LEHENGA,
  GRAPHITE_SUIT
}
interface BridalPartyPerson {
  name: string;
  team: Team;
  outfit: Outfit;
  location: string;
  isGettingReady: boolean;
  isPartying: boolean;
}

const bridalPartyPerson: BridalPartyPerson = new BridalPartyPerson({isGettingReady: true});

if (currentTime === new Date.setFullYear(2024).setMonth(10).setDate(8).setHours(18, 0, 0, 0)) {
  bridalPartyPerson.isGettingReady = false;
  bridalPartyPerson.location = 'Lakeview Pavilion';
  bridalPartyPerson.isPartying = true;
} else {
  bridalPartyPerson.isPartying = false;
}`}
                {/* {`const eventData = {
  name: "Reception",
  schedule: [
    {
      startTime: "6:00 PM",
      endTime: "7:00 PM",
      activity: "Cocktail Hour",
    },
    {
      startTime: "7:00 PM",
      endTime: "11:00 PM",
      activity: "Reception",
    },
  ],
  location: "Lakeview Pavilion",
  note: "Shuttles will be provided from Sonesta Select to and from the reception. (Times coming soon)"
};`} */}
              </SyntaxHighlighter>
            </div>
          </div>
        </section>
      )}
      {radioValue === "3" && (
        <section className="bg-white">
          <div className="py-4 max-w-screen-3xl lg:py-4">
            {isBridalPartyLoggedIn ? (
              <>
                <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
                  <h2 className="mt-2 mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                    Thursday November 7, 2024
                  </h2>
                </div>
                <div class="timeline-container animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto max-w-2xl">
                  <ul>
                    <li class="box">
                      <span></span>
                      <div class="title">Hindu Ceremony Rehearsal</div>
                      <div class="sub-title">Visible to Bridal Party Only</div>
                      <div class="info">
                        <p>Lakeview Pavilion</p>
                        <p>This is completely optional! If you are in the area, please join us</p>
                      </div>
                      <div class="time">
                        <span>4:00pm</span>
                        <span>5:00pm</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="timeline-container animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto max-w-2xl">
                  <ul>
                    <li class="box">
                      <span></span>
                      <div class="title">Church Rehearsal</div>
                      <div class="sub-title">Visible to Bridal Party Only</div>
                      <div class="info">
                        <p>Transfiguration of the Lord Parish at Saint Mary Church</p>
                        <p>This is completely optional! If you are in the area, please join us</p>
                      </div>
                      <div class="time">
                        <span>5:30pm</span>
                        <span>6:00pm</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                Friday November 8, 2024
              </h2>
            </div>
            <div class="timeline-container animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto max-w-2xl">
              <ul>
                <li class="box">
                  <span></span>
                  <div class="title">Hindu Ceremony</div>
                  {/* <div class="sub-title">created by M A R K</div> */}
                  <div class="info">
                    <p>10:45am - 1pm Ceremony</p>
                    {/* Details expander: Arrival of groom, hindu ceremony, hor deurves, lunch, vidhai */}
                    <p>1pm - 3pm Lunch and Madhuram Veppu</p>
                    <p>Lakeview Pavilion</p>
                  </div>
                  <div class="time">
                    <span>10:45pm</span>
                    <span>3:00pm</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="max-w-screen-md text-start mx-8 mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
                Saturday November 9, 2024
              </h2>
            </div>
            <div class="timeline-container animate-fade-down animate-duration-800 text-left flex flex-col p-6 mx-auto max-w-2xl">
              <ul>
                <li class="box">
                  <span></span>
                  <div class="title">Church Ceremony</div>
                  {/* <div class="sub-title">created by M A R K</div> */}
                  <div class="info">
                    <p>1 pm - 2 pm</p>
                    <p>Transfiguration of the Lord Parish at Saint Mary Church</p>
                  </div>
                  <div class="time">
                    <span>1:00pm</span>
                    <span>2:00pm</span>
                  </div>
                </li>
                <li class="box">
                  <span></span>
                  <div class="title">Reception</div>
                  {/* <div class="sub-title">created by M A R K</div> */}
                  <div class="info">
                    {" "}
                    <p>
                      Shuttles will be provided from Sonesta Select to and from the reception.
                      (Times coming soon)
                    </p>
                    <p>6pm - 7pm Cocktail Hour</p>
                    <p>7pm - 11pm Reception</p>
                    <p>Lakeview Pavilion</p>
                  </div>
                  <div class="time">
                    <span>6:00pm</span>
                    <span>11:00pm</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
