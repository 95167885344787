import { GameContext } from "./context/game-context";
import "./Game.css";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-modal";
import db from "../firebase";
import {
  Timestamp,
  collection,
  where,
  onSnapshot,
  orderBy,
  query,
  limit,
} from "firebase/firestore";

export default function Score() {
  const [highScore, setHighScore] = useState(0);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);

  const handleClose = () => setShowLeaderboard(false);
  const handleShow = () => setShowLeaderboard(true);

  const { score } = useContext(GameContext);

  useEffect(() => {
    const prevHighScore = localStorage.getItem("kideejhighscore");
    if (prevHighScore > 0) {
      setHighScore(prevHighScore);
    }
  }, []);

  useEffect(() => {
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem("kideejhighscore", score);
    }
  }, [score]);

  useEffect(() => {
    const leaderboardDb = collection(db, "leaderboard");
    const q = query(leaderboardDb, orderBy("score", "desc"), where("show", "==", true), limit(40));
    onSnapshot(q, (snapshot) => {
      let items = [];
      snapshot.docs.forEach((doc) => {
        let data = doc.data();
        const timestamp = data.timestamp;
        const timeAgoString = timeAgo(timestamp);
        let item = {
          name: data.name,
          score: data.score,
          show: true,
          time: timeAgoString,
        };
        items.push(item);
      });
      setLeaderboard(items);
    });
  }, []);

  function timeAgo(timestamp) {
    const now = Timestamp.now();
    const secondsAgo = now.seconds - timestamp.seconds;
    const daysAgo = Math.floor(secondsAgo / (60 * 60 * 24));

    if (daysAgo < 1) {
      return "Today";
    } else if (daysAgo === 1) {
      return "Yesterday";
    } else {
      return `${daysAgo} days ago`;
    }
  }

  const resetHighScore = () => {
    setHighScore(0);
    localStorage.removeItem("kideejhighscore");
  };

  const leaderboardIcon = () => {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="tooltip" className="tooltip">
            See Leaderboard
          </Tooltip>
        }
      >
        <button onClick={handleShow} className="float-right">
          <svg
            fill="#f5f5f5"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#f5f5f5"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M22,12H16.333V10a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v5H2a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V13A1,1,0,0,0,22,12ZM7.667,21H3V17H7.667Zm6.666-8v8H9.667V11h4.666ZM21,21H16.333V14H21ZM15,3.292,13.333,4.667,13.854,7,12,5.667,10.146,7l.521-2.333L9,3.292,11,3l1-2,1,2Z"></path>
            </g>
          </svg>
        </button>
      </OverlayTrigger>
    );
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "5",
      width: "70vw",
      height: "60vh",
      scrollbarWidth: "none",
    },
  };

  const leaderboardModal = () => {
    return (
      <Modal
        isOpen={showLeaderboard}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="tooltip" className="tooltip">
              See Leaderboard
            </Tooltip>
          }
        >
          <button onClick={handleClose} className="float-right">
            <svg
              width="20px"
              height="20px"
              viewBox="-0.5 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#808080"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokelineCap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M3 21.32L21 3.32001"
                  stroke="#808080"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M3 3.32001L21 21.32"
                  stroke="#808080"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </OverlayTrigger>
        <div>
          <h2 className="mb-6 text-2xl tracking-tight font-extrabold text-gray-500">Leaderboard</h2>

          <div id="leaderboard">
            <div className="ribbon"></div>
            <table>
              {leaderboard.map((leader, index) => {
                return (
                  <tr key={leader.name}>
                    <td className="number">{index + 1}</td>
                    <td className="name">{leader.name}</td>
                    <td className="points">
                      {leader.score}{" "}
                      {index === 0 ? (
                        <img
                          className="gold-medal"
                          src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true"
                          alt="gold medal"
                        />
                      ) : (
                        <span></span>
                      )}
                    </td>
                    {/* <td className="name">{leader.time}</td> */}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className="score">
        <span className="score-offset">Score</span>
        {leaderboardIcon()}
        <div>{score}</div>
      </div>
      <div className="score ml-4">
        <span className="score-offset">Best</span>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="tooltip" className="tooltip">
              Reset High Score
            </Tooltip>
          }
        >
          <button onClick={resetHighScore} className="float-right">
            <svg width="20px" height="20px" viewBox="0 0 21 21" fill="#000000">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="#f5f5f5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="matrix(0 1 1 0 2.5 2.5)"
                >
                  {" "}
                  <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"></path>{" "}
                  <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)"></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </button>
        </OverlayTrigger>
        <div>{highScore}</div>
      </div>
      {leaderboardModal()}
    </>
  );
}
